<template>
  <Registo />
</template>

<script>
import Registo from "@/components/users/Registo.vue";

export default {
  components: {
    Registo
  }
};
</script>
