var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":"","fill-height":""}},[_c('v-layout',{attrs:{"align-center":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm8":"","md4":""}},[_c('v-card',{staticClass:"elevation-12"},[_c('v-toolbar',{attrs:{"dark":"","color":"primary"}},[_c('v-toolbar-title',[_vm._v("Registo de utilizador")])],1),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-text-field',{attrs:{"prepend-icon":"person","name":"name","label":"Nome","type":"text","rules":_vm.regraNome,"required":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('v-text-field',{attrs:{"prepend-icon":"email","name":"email","label":"Email","type":"email","rules":_vm.regraEmail,"required":""},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('v-flex',[_c('v-autocomplete',{attrs:{"item-text":"label","item-value":"value","items":_vm.ent_list,"rules":_vm.regraEntidade,"prepend-icon":"account_balance","label":"Entidade","required":""},model:{value:(_vm.form.entidade),callback:function ($$v) {_vm.$set(_vm.form, "entidade", $$v)},expression:"form.entidade"}})],1),_c('v-flex',[_c('v-select',{attrs:{"items":[
                  'Administrador de Perfil Tecnológico',
                  'Administrador de Perfil Funcional',
                  'Utilizador Decisor',
                  'Utilizador Validador',
                  'Utilizador Avançado',
                  'Utilizador Arquivo Distrital',
                  'Utilizador Simples',
                  'Representante Entidade'
                ],"rules":_vm.regraTipo,"prepend-icon":"assignment","label":"Nível de utilizador","required":""},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}})],1),_c('v-text-field',{attrs:{"id":"password","prepend-icon":"lock","name":"password","label":"Password","type":"password","rules":_vm.regraPassword,"required":""},on:{"input":function($event){return _vm.verificaPassword()}},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('v-text-field',{attrs:{"id":"rep_password","prepend-icon":"lock","name":"rep_password","label":"Repita a Password","type":"password","rules":_vm.regraPassword,"required":""},on:{"input":function($event){return _vm.verificaPassword()}},model:{value:(_vm.form.rep_password),callback:function ($$v) {_vm.$set(_vm.form, "rep_password", $$v)},expression:"form.rep_password"}})],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"error","type":"submit"},on:{"click":_vm.cancelar}},[_vm._v("Cancelar")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","type":"submit"},on:{"click":_vm.registarUtilizador}},[_vm._v("Registar")])],1),_c('v-snackbar',{attrs:{"color":_vm.color,"timeout":_vm.timeout,"top":true},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.text)+" "),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.fecharSnackbar}},[_vm._v("Fechar")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }